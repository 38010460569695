<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <date-selector :class="'searchbar-content'"
                          @setStartDate="setStartDate"
                          @setEndDate="setEndDate"
                          :startDefault="startDefault"
                          :endDefault="endDefault"
                          :configs="dateConfigs"
                          :isOldYn="true"
                          @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
         </div>
      </div>   
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.id') }}</span></div>
           <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="$t('searchArea.id')" />
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{$t('searchArea.div')}}</span></div>
           <select v-model="reqData.searchType">
             <option value="">{{$t('searchArea.total')}}</option>
             <option value="bet">{{$t('common.bet')}}</option>
             <option value="cashIn">{{$t('common.deposit')}}</option>
             <option value="cashOut">{{$t('common.withdraw')}}</option>
             <option value="admin">{{$t('searchArea.adminInOut')}}</option>
             <option value="user">{{$t('searchArea.upperInOut')}}</option>
             <option value="point">{{$t('searchArea.pointEx')}}</option>
             <option value="bonus">{{$t('searchArea.bonus')}}</option>
           </select>
         </div>
         <div class="box-ui-select searchbar-content" v-if="reqData.searchType==='bet'">
           <div class="title"><span>{{$t('table.head.gameType')}}</span></div>
           <select v-model="reqData.vendorCode">
             <option value="">{{$t('searchArea.total')}}</option>
             <template v-for="item in gameList" :key="item.gameType">
               <option :value="item.code">{{ $i18n.locale === 'ko'? item.codeName : item['codeName' + $i18n.locale.toUpperCase()] }}</option>
             </template>
           </select>
         </div>
         <div class="box-ui-toggle searchbar-content">
           <div class="toggle-container">
             <div class="toggle-item">
               <span class="toggle-title">{{$t('common.includeBlack')}}</span>
               <input type="checkbox" class="set-switch" v-model="reqData.memBlackYn" />
             </div>
           </div>
         </div>
         <button class="btn-search mr-5" type="button" @click="pageSeach">
           <i class="fas fa-search"></i>
         </button>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <!-- todo: api -->

          <template v-if="tableData.length !== 0">
            <tbody>
              <tr v-for="item,idx in tableData" :key="idx">
                <td>{{pageInfo.tatal_list_count - idx}}</td>
                <td>{{numberWithCommas(item.cashAmt)}}</td>
                <td>{{item.processType}}</td>
                <td>{{item.memId}}</td>
                <!-- <td>{{item.betIdx}}</td>
                <td>{{item.gameType}}</td> -->
                <td>{{item.detail}}</td>
                <!-- todo: 보유잔액 수정중 -->
                <td>0</td>
                <td>{{replaceDateT(item.updDate)}}</td>
              </tr>
            </tbody>
          </template>
          <template v-else>
            <tr>
              <td colspan="11">{{ $t('txt.noData') }}</td>
            </tr>
          </template>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="goPage" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import lodash from 'lodash'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { reportTrade, getCode } from '@/api/member.js'
import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'

export default {
  name: 'ReportTrade',
  components: {
    SearchFilter,
    DateSelector,
    TableHead,
    UiCheck,
    subTitle,
    Pagination
  },
  data: function () {
    return {
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'amount', 'transaction', 'memId', 'detail', 'balance_2', 'date']
      },
      reqData: {
        page: 1,
        count_per_list: '30',
        memId: '',
        memBlackYn: 'N',
        startDate: '',
        endDate: '',
        searchType: '',
        vendorCode: ''
      },
      pageInfo: {
        tatal_list_count: 0,
        page: 0,
        tatal_page_count: 0
      },
      totalInfo: {},
      tableData: [],
      codeList: [],
      convertData: {},
      gameType: {},
      currentPage: '',
      gameList: [],
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: ''
    }
  },
  watch: {
    reqData: {
      deep: true,
      handler (item) {
        if (item.searchType !== 'bet') {
          item.gameType = ''
        }
      }
    }
  },
  methods: {
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    async getGameCode (masterCode) {
      return await getCode(masterCode)
    },
    async setGameList () {
      this.gameList = await this.getGameCode('kplay')
      console.log(this.gameList)
    },
    pageSeach () {
      this.setTableData(1)
    },
    goPage (pageNum) {
      this.setTableData(pageNum)
    },
    async setTableData (pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      const req = lodash.cloneDeep(this.reqData)
      for (const key in req) {
        if (typeof req[key] === 'boolean') {
          if (req[key]) {
            req[key] = ''
          } else {
            req[key] = 'N'
          }
        }
      }
      console.log(req)
      this.emitter.emit('Loading', true)
      const res = await reportTrade(req)
      this.emitter.emit('Loading', false)
      if (res.resultCode === '0') {
        const dataList = res.data.list
        console.log(dataList)
        for (const data of dataList) {
          if (data.processType === 'bet') {
            data.processType = '베팅'
          }
          if (data.detail === 'bet') {
            data.detail = '베팅'
          }
          if (data.detail === '베팅성공') {
            data.detail = '당첨금'
          }
        }
        this.tableData = dataList
        if (res.data.pageInfo) {
          this.pageInfo = res.data.pageInfo
          const totalPage = this.pageInfo.tatal_list_count / this.pageInfo.count_per_list
          if (isNaN(totalPage)) {
            this.pageInfo.tatal_page_count = 0
          } else {
            this.pageInfo.tatal_page_count = Math.ceil(totalPage)
          }
        }
      }
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    console.log(this.$route.query.id)
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    this.setStartDate()
    this.setEndDate()
    await this.setGameList()
    await this.setTableData(1)
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
</style>
